<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        label="ชื่อสินค้า"
        v-model="search"
        class="col-12">
      </sgv-input-text>
    </div>

    <ul>
      <li
        v-for="order in orders"
        :key="order.id">
        <router-link
          :to="toDoc(order.doc)"
          class="text-decoration-none text-info">
          {{order.doc.code}}
        </router-link>
        <span class="ml-2">{{order.name}}</span>
        <small>
          <div>
            <span class="text-warning">จำนวน: {{order.qty | comma(-1)}} {{order.unit.name}}</span>
            | <span class="text-primary">ราคา: {{order.price | comma(-1)}} บาท</span>
            | <span class="text-success">รวม: {{order.totalPrice | comma(-1)}} บาท</span>
          </div>
        </small>
      </li>
    </ul>
  </div>
</template>

<script>
import { LIST_ORDER_HISTORY } from './graph/search'

export default {
  props: {
    contactId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      search: '',
      orders: []
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER_HISTORY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.contactId,
          q: this.getFilter()
        }
      },
      debounce: 300,
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    getFilter () {
      const filter = {
        limit: 10,
        params: {
          search: this.search
        }
      }

      return filter
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
