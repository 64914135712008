var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.row.parentId || _vm.selectedRow === _vm.row.parentId)?_c('tr',[_c('td',[(_vm.canDestroy && (_vm.selectedRow === _vm.row.id || _vm.selectedRow === _vm.row.parentId) && !['vatPrice', 'subVatPrice', 'netPrice', 'subNetPrice'].includes(_vm.row.type))?_c('fa',{staticClass:"text-danger pointer",attrs:{"icon":"trash"},on:{"dblclick":function($event){return _vm.destroyOrder(_vm.row.id, _vm.row.parentId)}}}):_vm._e(),(!_vm.row.parentId)?_c('span',[_c('span',{staticClass:"pointer",class:{'text-warning': _vm.selected === _vm.row.id},on:{"dblclick":function($event){$event.stopPropagation();return _vm.emitInput(_vm.row)},"click":function($event){$event.stopPropagation();return _vm.selectOrder(_vm.row)}}},[_vm._v(" "+_vm._s(_vm.row.id)+" ")]),_vm._l((_vm.getChildren(_vm.invoiceType, _vm.row.children)),function(child){return _c('router-link',{key:child.id,staticClass:"text-decoration-none text-nowrap",class:{'text-warning': !child.closedAt, 'text-success': child.closedAt},attrs:{"to":_vm.toDoc(child)}},[_c('div',[_c('small',[_c('fa',{attrs:{"icon":"truck"}}),_vm._v(" "+_vm._s(child.code)+" ")],1)])])})],2):_vm._e(),(_vm.row.parentId && _vm.row.docId !== _vm.docId)?_c('span',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toDoc(_vm.row.doc)}},[_c('small',{staticClass:"text-nowrap",class:{'text-warning': !_vm.row.doc.closedAt, 'text-success': !!_vm.row.doc.closedAt}},[_c('br'),(_vm.row.doc.type === _vm.invoiceType)?_c('fa',{attrs:{"icon":"truck"}}):_vm._e(),(_vm.row.doc.type === _vm.billingType)?_c('fa',{attrs:{"icon":"file-invoice-dollar"}}):(_vm.row.doc.type === _vm.receiptType)?_c('fa',{attrs:{"icon":"dollar-sign"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.row.doc.code)+" ")],1)])],1):_vm._e()],1),_c('td',[_vm._l((_vm.row.ledgers),function(ledger){return _c('span',{key:ledger.id},[_c('small',{staticClass:"text-nowrap",class:{
        'text-success': ledger.approvedAt,
        'text-warning': !ledger.approvedAt
        }},[_vm._v(" "+_vm._s(ledger.account.code)+": "+_vm._s(ledger.account.name)+" "),(ledger.approvedAt)?_c('span',{staticClass:"text-secondary"},[_vm._v("("+_vm._s(_vm._f("date")(ledger.approvedAt))+")")]):_vm._e()]),_c('br')])}),_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.row.name))]),_vm._l((_vm.row.stocks),function(stock){return _c('span',{key:stock.id,staticClass:"text-primary text-nowrap"},[_c('br'),_c('small',[_c('em',[_vm._v(_vm._s(stock.inventory.code)+" ("+_vm._s(stock.inventory.name)+")")])])])})],2),_c('td',{staticClass:"text-nowrap"},[_vm._l((_vm.row.ledgers),function(ledger){return _c('span',{key:ledger.id},[_c('br')])}),(['vatPrice', 'netPrice'].includes(_vm.row.type))?_c('span',[_vm._v(" - ")]):_c('span',[(_vm.row.type === 'item' && !_vm.row.parentId && _vm.canCheckout && _vm.getRemaining(_vm.row) > 0)?_c('DetailOrderCheckoutInventory',{attrs:{"docType":_vm.docType,"templateType":_vm.templateType,"orderId":_vm.row.id,"inventory":_vm.row.inventory,"remaining":_vm.getRemaining(_vm.row)}}):_vm._e(),_c('span',{class:{'text-success': _vm.row.qty > 0, 'text-danger': _vm.row.qty < 0}},[_vm._v(" "+_vm._s(_vm._f("comma")(Number(_vm.row.qty)))+" ")]),(_vm.row.type === 'item' && !_vm.row.parentId)?_c('span',[_vm._v(" ( "),_c('span',{class:_vm.getRemaining(_vm.row) === 0 ? ['text-success'] : ['text-danger']},[_vm._v(" "+_vm._s(_vm._f("comma")(_vm.getRemaining(_vm.row)))+" ")]),_vm._v(" ) ")]):_vm._e(),(_vm.row.unit)?_c('span',[_vm._v(_vm._s(_vm.row.unit.name))]):_vm._e()],1),_vm._l((_vm.row.stocks),function(stock){return _c('small',{key:stock.id},[_c('br'),_c('span',{staticClass:"pointer",class:{
        'text-success': stock.approvedAt,
        'text-warning': !stock.approvedAt
        }},[_vm._v(" TAG: "+_vm._s(stock.id)+" ")]),_vm._v(" ( "),_c('span',{class:{
            'text-success': stock.qty > 0,
            'text-danger': stock.qty < 0
            }},[_vm._v(" "+_vm._s(_vm._f("comma")(stock.qty))+" ")]),_vm._v(" ) "),_c('span',[_vm._v(" "+_vm._s(stock.inventory.unit.name)+" ")]),_c('span',{staticClass:"text-secondary"},[_vm._v(" ("+_vm._s(stock.parentId)+") ")]),(stock.approvedAt)?_c('span',{staticClass:"text-secondary"},[_vm._v(" ("+_vm._s(_vm._f("date")(stock.approvedAt))+") ")]):_vm._e()])})],2),_c('td',[_vm._l((_vm.row.ledgers),function(ledger){return _c('span',{key:ledger.id},[_c('br')])}),(_vm.row.price > 0)?_c('span',[_vm._v(_vm._s(_vm._f("comma")(_vm.row.price)))]):_c('span',[_vm._v("-")]),(_vm.row.discount)?_c('small',{staticClass:"text-primary"},[_c('br'),_vm._v(_vm._s(_vm.row.discount)+" ("+_vm._s(_vm.row.discountPrice)+") ")]):_vm._e()],2),_c('td',[_vm._l((_vm.row.ledgers),function(ledger){return _c('span',{key:ledger.id},[_c('small',{class:{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}},[_vm._v(" "+_vm._s(_vm._f("comma")(Math.abs(ledger.amount)))+" ")]),_c('br')])}),_vm._v(" "+_vm._s(_vm._f("comma")(_vm.row.totalPrice))+" ")],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }