<template>
  <tr v-if="!row.parentId || selectedRow === row.parentId">
    <td>
      <fa
        v-if="canDestroy && (selectedRow === row.id || selectedRow === row.parentId) && !['vatPrice', 'subVatPrice', 'netPrice', 'subNetPrice'].includes(row.type)"
        icon="trash"
        class="text-danger pointer"
        @dblclick="destroyOrder(row.id, row.parentId)">
      </fa>
      <span v-if="!row.parentId">
        <span
          class="pointer"
          :class="{'text-warning': selected === row.id}"
          @dblclick.stop="emitInput(row)"
          @click.stop="selectOrder(row)">
          {{row.id}}
        </span>
        <router-link
          v-for="child in getChildren(invoiceType, row.children)"
          :key="child.id"
          :class="{'text-warning': !child.closedAt, 'text-success': child.closedAt}"
          class="text-decoration-none text-nowrap"
          :to="toDoc(child)">
          <div>
            <small>
              <fa icon="truck"></fa>
              {{child.code}}
            </small>
          </div>
        </router-link>
      </span>
      <span v-if="row.parentId && row.docId !== docId">
        <router-link
          :to="toDoc(row.doc)"
          class="text-decoration-none">
          <small
            :class="{'text-warning': !row.doc.closedAt, 'text-success': !!row.doc.closedAt}"
            class="text-nowrap">
            <br>
            <fa icon="truck" v-if="row.doc.type === invoiceType"></fa>
            <fa icon="file-invoice-dollar" v-if="row.doc.type === billingType"></fa>
            <fa icon="dollar-sign" v-else-if="row.doc.type === receiptType"></fa>
            {{row.doc.code}}
          </small>
        </router-link>
      </span>
    </td>
    <td>
      <span v-for="ledger in row.ledgers" :key="ledger.id">
        <small :class="{
          'text-success': ledger.approvedAt,
          'text-warning': !ledger.approvedAt
          }"
          class="text-nowrap">
          {{ledger.account.code}}: {{ledger.account.name}}
          <span v-if="ledger.approvedAt" class="text-secondary">({{ledger.approvedAt | date}})</span>
        </small>
        <br>
      </span>
      <span style="white-space: pre-line;">{{ row.name }}</span>
      <span
        v-for="stock in row.stocks"
        :key="stock.id"
        class="text-primary text-nowrap">
        <br>
        <small>
          <em>{{ stock.inventory.code }} ({{stock.inventory.name}})</em>
        </small>
      </span>
    </td>
    <td class="text-nowrap">
      <span v-for="ledger in row.ledgers" :key="ledger.id"><br></span>

      <span v-if="['vatPrice', 'netPrice'].includes(row.type)">
        -
      </span>
      <span v-else>
        <DetailOrderCheckoutInventory
          v-if="row.type === 'item' && !row.parentId && canCheckout && getRemaining(row) > 0"
          :docType="docType"
          :templateType="templateType"
          :orderId="row.id"
          :inventory="row.inventory"
          :remaining="getRemaining(row)">
        </DetailOrderCheckoutInventory>

        <span :class="{'text-success': row.qty > 0, 'text-danger': row.qty < 0}">
          {{ Number(row.qty) | comma }}
        </span>

        <span v-if="row.type === 'item' && !row.parentId">
          (
          <span :class="getRemaining(row) === 0 ? ['text-success'] : ['text-danger']">
            {{ getRemaining(row) | comma }}
          </span>
          )
        </span>

        <span v-if="row.unit">{{ row.unit.name }}</span>
      </span>

      <small v-for="stock in row.stocks" :key="stock.id">
        <br>
        <span :class="{
          'text-success': stock.approvedAt,
          'text-warning': !stock.approvedAt
          }"
          class="pointer">
          TAG: {{stock.id}}
        </span>
        (
          <span
            :class="{
              'text-success': stock.qty > 0,
              'text-danger': stock.qty < 0
              }">
            {{stock.qty | comma}}
          </span>
        )
        <span>
          {{stock.inventory.unit.name}}
        </span>
        <span class="text-secondary">
          ({{stock.parentId}})
        </span>
        <span v-if="stock.approvedAt" class="text-secondary">
          ({{stock.approvedAt | date}})
        </span>
      </small>
    </td>
    <td>
      <span v-for="ledger in row.ledgers" :key="ledger.id"><br></span>
      <span v-if="row.price > 0">{{ row.price | comma  }}</span>
      <span v-else>-</span>
      <small v-if="row.discount" class="text-primary">
        <br>{{row.discount}} ({{row.discountPrice}})
      </small>
    </td>
    <td>
      <span v-for="ledger in row.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
        <br>
      </span>
      {{ row.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
import DetailOrderCheckoutInventory from './DetailOrderCheckoutInventory'
import { DESTROY_ORDER, UNCHECK_ORDER } from './graph'

export default {
  name: 'SubOrderItem',
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    selected: {
      type: Number,
      required: false
    },
    canDestroy: {
      type: Boolean,
      required: false
    },
    canCheckout: {
      type: Boolean,
      required: false
    },
    invoiceType: {
      type: String,
      required: true
    },
    receiptType: {
      type: String,
      required: true
    },
    billingType: {
      type: String,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    selectedRow () {
      return this.value.find(v => v === this.row.id || v === this.row.parentId)
    }
  },
  methods: {
    selectOrder (row) {
      if (row.type === 'item') {
        if (this.selected === row.id) {
          this.$emit('update:selected', null)
        } else {
          this.$emit('update:selected', row.id)
        }
      }
    },
    getChildren (type, children) {
      return children
      .filter(v => v.docId !== this.docId && v.doc.type === type)
      .reduce((t,v) => {
        const found = t.find(x => x.id === v.doc.id)
        if (!found) t.push(v.doc)
        return t
      }, [])
    },
    getRemaining (row) {
      return row.children.reduce((t,child) => {
        return child.stocks.reduce((sub,v) => sub += parseFloat(v.qty), t)
      }, parseFloat(row.qty))
    },
    emitInput (row) {
      if (row.parentId) return
      const found = this.value.indexOf(row.id)
      const arr = [...this.value]
      if (found === -1) {
        arr.push(row.id)
      } else {
        arr.splice(found, 1)
      }
      this.$emit('input', arr)
    },
    destroyOrder (id, parentId) {
      this.$apollo.mutate({
        mutation: parentId ? UNCHECK_ORDER(this.templateType) : DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitOrderUpdated () {
      this.$emit('updated', null)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  },
  components: {
    DetailOrderCheckoutInventory
  }
}
</script>

<style lang="css" scoped>
</style>
