<template>
  <span>
    <span
      @click="toggle = true"
      class="text-warning pointer">
      <fa icon="cubes"></fa>
    </span>

    <b-modal v-model="toggle" :title="`${inventory.code} (${inventory.name})`">
      <div class="row">
        <sgv-input-number
          label="จำนวน"
          v-model="qty"
          :precision="2">
        </sgv-input-number>
      </div>

      <template v-slot:modal-footer="{cancel}">
        <button
          type="button"
          class="btn btn-success"
          @click="checkoutStock">
          ตัดสต๊อก
        </button>

        <button
          type="button"
          class="btn btn-danger"
          @click="cancel()">
          กลับ
        </button>
      </template>
    </b-modal>

  </span>
</template>

<script>
import debounce from 'lodash/debounce'
import { CHECKOUT_ORDER_INVENTORY } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    },
    remaining: {
      type: Number,
      required: true
    },
    inventory: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      qty: this.remaining
    }
  },
  methods: {
    checkoutStock () {
      this.$apollo.mutate({
        mutation: CHECKOUT_ORDER_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          orderId: this.orderId,
          qty: this.qty
        }
      })
      .then(() => {
        this.$toasted.global.success("ตัดสต๊อกสำเร็จ")
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  watch: {
    toggle (v) {
      if (v === false) {
        this.qty = 0
      } else {
        this.qty = this.remaining
      }
    }
  },
  created () {
    this.debouncedCheckoutStock = debounce(this.checkoutStock, 150)
  }
}
</script>
