<template>
  <sgv-input-text
    disabled
    :value="mapText"
    label="ยอดคงเหลือ">
  </sgv-input-text>
</template>

<script>
import { LIST_STOCK_BALANCE } from './graph'

export default {
  name: 'DetailOrderInventoryInputPrice',
  props: {
    inventoryDocConfigId: {
      type: Number,
      required: false
    },
    inventoryId: {
      type: Number,
      required: false
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      stocks: [],
    }
  },
  apollo: {
    stocks: {
      query () {
        return LIST_STOCK_BALANCE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          inventoryIds: [this.inventoryId],
        }
      },
      skip () {
        return !this.inventoryId
      },
      debounce: 300,
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    stock () {
      return this.stocks.find(v => v.inventoryId = this.inventoryId)
    },
    mapText () {
      if (!this.stock) return ''
      return `${this.stock.current} -> ${this.stock.future} ${this.stock.inventory.unit.name}`
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.stocks.refetch()
    }
  },
  watch: {
    inventoryDocConfigId () {
      this.stocks = []
    }
  }
}
</script>

<style lang="css">
</style>
