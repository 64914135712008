<template>
  <sgv-input-text
    type="search"
    ref="stockIdInput"
    placeholder="ตัดสต๊อกสินค้า..."
    v-model="stockId"
    @keyup.enter="checkoutStock">
  </sgv-input-text>
</template>

<script>
import { CHECKOUT_ORDER } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    orderId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      stockId: '',
    }
  },
  methods: {
    classifyBarcode (txt) {
      if (txt.indexOf('stockId,') === -1) {
        return +txt
      } else {
        return +txt.replace('stockId,', '')
      }
    },
    checkoutStock () {
      this.$apollo.mutate({
        mutation: CHECKOUT_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          stockId: this.classifyBarcode(this.stockId),
          docId: this.docId,
          orderId: this.orderId
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
      .finally(() => {
        this.stockId = ''
        this.$refs.stockIdInput.setFocus()
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
