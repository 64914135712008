<template>
  <sgv-table
    :items="orders"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <div
      class="form-row"
      slot="action"
      v-if="formData.approvedAt && $auth.hasRole(`doc:${docType}:checkout`)">
      <DetailOrderCheckoutInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :orderId="selectedOrder"
        class="col-12 col-md-8 col-lg-7 mb-xs-3 mb-sm-0">
      </DetailOrderCheckoutInput>
    </div>

    <template slot="option" v-if="options.toolbar === 'itemData'">
      <DetailOrderInventoryInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderInventoryInput>

      <DetailOrderExpenseInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderExpenseInput>

      <DetailOrderDiscountInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :orders="orders">
      </DetailOrderDiscountInput>
    </template>

    <template slot="option" v-if="options.toolbar === 'search'">
      <DetailOrderSearchHistory
        :templateType="templateType"
        :docType="docType"
        :contactId="formData.contactId">
      </DetailOrderSearchHistory>
    </template>

    <template slot-scope="row">
      <DetailOrderSubOrderItem
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :invoiceType="invoiceType"
        :receiptType="receiptType"
        :billingType="billingType"
        :row="row.item"
        v-model="selectedRows"
        :selected.sync="selectedOrder"
        :canDestroy="!formData.closedAt"
        :canCheckout="formData.approvedAt && !formData.closedAt && $auth.hasRole(`doc:${docType}:checkout:inventory`)">
      </DetailOrderSubOrderItem>

      <DetailOrderSubOrderItem
        v-for="child in row.item.children"
        :key="child.id"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :invoiceType="invoiceType"
        :receiptType="receiptType"
        :billingType="billingType"
        :row="child"
        :value="selectedRows"
        :canDestroy="!formData.closedAt"
        :canCheckout="formData.approvedAt && !formData.closedAt && $auth.hasRole(`doc:${docType}:checkout:inventory`)">
      </DetailOrderSubOrderItem>
    </template>
  </sgv-table>
</template>

<script>
import { round } from 'lodash'
import DetailOrderSubOrderItem from './DetailOrderSubOrderItem.vue'
import DetailOrderCheckoutInput from './DetailOrderCheckoutInput.vue'
import DetailOrderInventoryInput from './DetailOrderInventoryInput.vue'
import DetailOrderExpenseInput from './DetailOrderExpenseInput.vue'
import DetailOrderDiscountInput from './DetailOrderDiscountInput.vue'
import DetailOrderSearchHistory from './DetailOrderSearchHistory.vue'
import {
  LIST_ORDER,
  WATCH_ORDER_UPDATED,
  WATCH_ORDER_DESTROYED,
  WATCH_ORDER_CHECKOUTED,
  CHECKOUT_ORDER_EXPENSE,
  WATCH_ORDER_REFETCH
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    invoiceType: {
      type: String,
      required: true
    },
    receiptType: {
      type: String,
      required: true
    },
    billingType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'qty', 'unit', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      selectedRows: [],
      selectedOrder: null
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables() {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      update (data) {
        return [
          ...data.orders.filter(v => v.type === 'item'),
          ...data.orders.filter(v => v.type === 'service'),
          ...data.orders.filter(v => v.type === 'expense'),
          ...data.orders.filter(v => v.type === 'discountPrice'),
          ...data.orders.filter(v => v.type === 'vatPrice'),
          ...data.orders.filter(v => v.type === 'netPrice')
        ]
      },
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const orderIndex = previous.orders.findIndex(v => v.id === subscriptionData.data.orderUpdated.id)
            let newOrders

            if (orderIndex !== -1) {
              newOrders = [...previous.orders]
              newOrders[orderIndex] = subscriptionData.data.orderUpdated
            } else {
              newOrders = [
                ...previous.orders,
                subscriptionData.data.orderUpdated
              ]
            }

            return {
              ...previous,
              orders: newOrders
            }
          }
        },
        {
          document () {
            return WATCH_ORDER_CHECKOUTED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const orderIndex = previous.orders.findIndex(v => v.id === subscriptionData.data.orderCheckouted.parentId)
            if (orderIndex === -1) return
            const newOrders = [...previous.orders]
            newOrders[orderIndex].children.push(subscriptionData.data.orderCheckouted)
            const result = {
              ...previous,
              orders: newOrders
            }
            return result
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const {id, parentId} = subscriptionData.data.orderDestroyed
            let newOrders = []
            if (parentId) {
              const parentIndex = previous.orders.findIndex(v => v.id === parentId)
              if (parentIndex === -1) return
              const orderIndex = previous.orders[parentIndex].children.findIndex(v => v.id === id)
              if (orderIndex === -1) return
              newOrders = [...previous.orders]
              newOrders[parentIndex].children.splice(orderIndex, 1)
            } else {
              const orderIndex = previous.orders.findIndex(v => v.id === id)
              if (orderIndex === -1) return
              newOrders = [...previous.orders]
              newOrders.splice(orderIndex, 1)
            }
            const result = {
              ...previous,
              orders: newOrders
            }
            return result
          }
        },
      ]
    },
    $subscribe: {
      orderRefetch: {
        query () {
          return WATCH_ORDER_REFETCH(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.$apollo.queries.orders.refetch()
        },
      }
    },
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      let arr = []
      if (!this.formData.approvedAt && this.docId > 0 && this.$auth.hasRole(`doc:${this.docType}:add`)) {
        arr.push({value: 'itemData', icon: 'plus', class: 'text-success'})
      }
      if (this.formData.approvedAt && !this.formData.closedAt && this.docId > 0 && this.$auth.hasRole(`doc:${this.docType}:add`)) {
        arr.push({func: this.checkoutExpense, icon: 'file-invoice', class: 'text-info'})
      }

      arr.push({value: 'search', icon: 'search', class: 'text-warning'})

      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    ledgersSum () {
      let total = 0
      this.orders.forEach(order => {
        total += order.ledgers.reduce((sub, ledger) => sub += +ledger.amount, 0)
        order.children.forEach(child => {
          total += child.ledgers.reduce((sub, ledger) => sub += +ledger.amount, 0)
        })
      })
      return round(total, 2)
    },
  },
  methods: {
    checkoutExpense () {
      this.orders
      .filter(v => ['service', 'expense'].includes(v.type))
      .map(v => ({
        docType: this.docType,
        docId: this.docId,
        orderId: v.id
      }))
      .forEach(v => {
        this.$apollo.mutate({
          mutation: CHECKOUT_ORDER_EXPENSE(this.templateType),
          variables: v
        })
        .then(() => {
          this.$toasted.global.success("เพิ่มสำเร็จ")
        })
        .catch(this.$toasted.global.error)
      })
    }
  },
  watch: {
    orders () {
      this.selectedOrder = null
    },
    'formData.approvedAt': {
      handler () {
        this.options.toolbar = null
      }
    }
  },
  components: {
    DetailOrderSubOrderItem,
    DetailOrderCheckoutInput,
    DetailOrderInventoryInput,
    DetailOrderExpenseInput,
    DetailOrderDiscountInput,
    DetailOrderSearchHistory
  }
}
</script>

<style lang="css">
</style>
