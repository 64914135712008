<template>
  <sgv-input-select
    :options="inventories"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="id">
    <template slot-scope="option">
      {{option.salesPrice}}
    </template>
  </sgv-input-select>
</template>

<script>
import { LIST_ORDER_INVENTORY } from './graph'

export default {
  name: 'DetailOrderInventoryInputPrice',
  props: {
    inventoryDocConfigId: {
      type: Number,
      required: false
    },
    qty: {
      type: Number,
      required: false
    },
    docType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    value: {
      type: [String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      display: null,
      inventories: [],
    }
  },
  apollo: {
    inventories: {
      query () {
        return LIST_ORDER_INVENTORY(this.templateType)
      },
      variables () {
        this.emitInput(null)
        return {
          docType: this.docType,
          type: this.type,
          contactId: this.contactId,
          qty: this.qty || 0,
          docConfigId: this.inventoryDocConfigId
        }
      },
      skip () {
        return !this.inventoryDocConfigId
      },
      debounce: 300,
      fetchPolicy: 'no-cache',
      result (res) {
        const found = res.data.inventories.find(v => v.isDefault)
        if (found) this.emitInput(found.id)
        else this.emitInput(null)
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css">
</style>
